import { createStore } from "vuex";

export default createStore({
  state: {
    project : 'JamSplit',
    desc : '',
    username : '',
    email: '',
    theme: 'light'
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
});
 