import { createApp } from 'vue'
import { createHead } from '@vueuse/head';
import vuetify from '@/plugins/vuetify'; // Vue-Router

import router from './router'; // Vue-Router
import store from "./store"; // Vuex
import * as filter from './filter'; // Filter

// Import the necessary Firebase modules
import { onAuthStateChanged } from "firebase/auth";
import { auth } from '@/config/firebase';

import App from './App.vue';
import './index.css';

const head = createHead();
// Create Vue Instance
const app = createApp(App);

app.use(vuetify);
app.use(router);
app.use(store);
app.use(head);

// Global Var 
app.config.globalProperties.$store = store;
app.config.globalProperties.$filters = filter;
app.config.globalProperties.$router = router;

app.mount('#app');

// Handle authentication state changes and routing logic
onAuthStateChanged(auth, (user) => {
  if (user) {
    console.log('User is signed in:', user);
    
    store.state.username =  user.displayName;
    store.state.email =  user.email;
    // If the user is on the home page and logged in, redirect to groups
    if (router.currentRoute.value.path === '/') {
      router.replace('/sessions');
    }
  } else {
    console.log('User is signed out');
    // If the user is signed out, redirect to home page
    if (router.currentRoute.value.path !== '/') {
      router.replace('/');
    }
  }
});

// Add router guard to handle navigation logic
router.beforeEach((to, from, next) => {
  if (to.path !== '/' && !auth.currentUser) {
    next({ path: '/' });
  } else if (to.path === '/' && auth.currentUser) {
    next({ path: '/' });
  } else {
    next();
  }
});
