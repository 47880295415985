import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getDatabase } from "firebase/database";

// Your Firebase project configuration (from Firebase Console)
const firebaseConfig = {
    apiKey: "AIzaSyBK2atko1-_EP9b_DVYSX5pCj0qkcBd85s",
    authDomain: "jamsplit-ed6d4.firebaseapp.com",
    databaseURL: "https://jamsplit-ed6d4-default-rtdb.firebaseio.com",
    projectId: "jamsplit-ed6d4",
    storageBucket: "jamsplit-ed6d4.appspot.com",
    messagingSenderId: "446975404495",
    appId: "1:446975404495:web:54c037d939445bd46d8aba",
    measurementId: "G-SCZDSH4JZ1"
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
const auth = getAuth(firebaseApp);
const storage = getStorage(firebaseApp);
const db = getFirestore(firebaseApp);
const database = getDatabase(firebaseApp);

export { auth, storage, db, database };
