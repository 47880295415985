<template>
  <router-view />
</template>

<script setup>
import { useHead } from '@vueuse/head';

useHead({
  title: 'Home Page - JamSplit',
  meta: [
    { name: 'description', content: 'JamSplit is a web application that makes it easy for bands and musicians to upload, edit and share recordings of their jam sessions and performances' },
    { property: 'og:title', content: 'Home Page - JamSplit' },
    { property: 'og:site_name', content: 'JamSplit' },
    { property: 'og:description', content: 'JamSplit is a web application that makes it easy for bands and musicians to upload, edit and share recordings of their jam sessions and performances' },
  ],
});

</script>

<style>

</style>
